import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../layout'
import ProjectDetail from './project/project-detail'

class ProjectPageTemplate extends React.Component {
  render() {
    const project = get(this.props, 'data.main')
//    console.log('project', project, project.poster[0].image.gatsbyImageData.images.fallback.src)
    const otherLangSlug = this.props.data.otherLang.slug
    project.otherLangPosters = this.props.data.otherLang.poster
    const lang = this.props.path.startsWith('/en/') ? 'en' : 'it'
    const title = `${project.title} - Andrea Martines`
    const metaDescription = project.description
    const image = project.poster[0].image.gatsbyImageData.images.fallback.src
    var generi = ''
    project.genre.forEach((item) => {
      generi += ', ' + item
    })
    generi = generi.substring(2)
    const duration = `PT${project.length}M`
    const SD = {
      "@context": "https://schema.org",
      "@type": "Movie",
      "name": project.title,
      "headline": project.title,
      "author": [
        {
          "@type": "Person",
          "name": "Andrea Martines"
        }
      ],
      "description": project.description,
      "abstract": project.description,
      "genre": generi,
      "dateCreated": project.dateCreated,
      "creativeWorkStatus": "Under development",
      "image": image,
      "countryOfOrigin": {
        "@type": "Country",
        "name": "Italy"
      },
      "inLanguage": "it, en",
      "duration": duration,
      "copyrightNotice": "Copyright 2021 Andrea Martines - All rights reserved"
    }

    return (
      <Layout
        {...{ otherLangSlug }}
        // project={project}
      >
        <Helmet
          title={title}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            // {
            //   name: `image`,
            //   content: image,
            // },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            // {
            //   property: `og:image`,
            //   content: image,
            // },
            // {
            //   name: `twitter:creator`,
            //   content: site.siteMetadata?.social?.twitter || ``,
            // },
            {
              name: `twitter:title`,
              content: title,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
          ]}
        >
          <script type="application/ld+json">{JSON.stringify(SD)}</script>
        </Helmet>

        <ProjectDetail {...{ project }} {...{ lang }} />
      </Layout>
    )
  }
}
export default ProjectPageTemplate
export const pageQuery = graphql`
  query ProjectBySlug1($id: String!, $lang: String!) {
    main: contentfulProject(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      description
      slug
      year
      type
      numberOfEpisodes
      length
      episodeLength
      developmentStatus
      statusInProgress
      rights
      budget
      ageRating
      production
      poster {
        label
        strangerLabel
        type
        description {
          childMarkdownRemark {
            html
          }
        }
        credits {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, width: 1000)
        }
      }
      images {
        label
        strangerLabel
        type
        description {
          childMarkdownRemark {
            html
          }
        }
        credits {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, width: 1000)
        }
      }
      pitch {
        childMarkdownRemark {
          html
        }
      }
      synopsis {
        childMarkdownRemark {
          html
        }
      }
      genre
      awards {
        contest
        contestAcronym
        year
        form
        award
        image {
          title
          description
          gatsbyImageData(placeholder: BLURRED, width: 250)
        }
      }
      documents {
        label
        type
        numberOfPages
        developmentStatus
        publicDownload
        documentId
        language
      }
      music {
        title
        performer
        author
        url
        type
      }
    }
    otherLang: contentfulProject(
      contentful_id: { eq: $id }
      node_locale: { ne: $lang }
    ) {
      slug
      poster {
        label
        strangerLabel
        type
        description {
          childMarkdownRemark {
            html
          }
        }
        credits {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED, width: 1000)
        }
      }
    }
  }
`
