import React, { useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Modale from '../../modal'
import { orderFilesForLang } from '../../../../common/utils'

const isBrowser = typeof window !== 'undefined'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 30px;
  justify-content: flex-start;
  font-size: 1rem;
  font-family: 'Nunito';
`
const Download = styled.h2`
  width: 100%;
  font-family: 'Lato';
  font-weight: 300;
  font-size: 1rem;
  text-transform: uppercase;
  @media (max-width: 700px) {
    width: 100%;
  }
`
const Chi = styled.fieldset`
  border: none;
  width: 45%;
  display: block;
  float: left;
`
const Cosa = styled.fieldset`
  border: none;
  width: 45%;
  display: block;
`
const Campo = styled.label`
  display: block;
  margin-top: 10px;
`
const Etichetta = styled.span`
  display: block;
  font-weight: bold;
`
const Pulsante = styled.button`
  font-family: Nunito;
  font-weight: bold;
  font-size: 16px;
  border: none;
  padding: 4px 12px;
  color: #fff;
  background-color: #cc0000 !important;
  cursor: pointer;
  &:hover {
    background-color: #ee0000 !important;
  }
`

const Doc = styled.div`
  margin-bottom: 10px;
  & a {
    color: #cc0000 !important;
    display: block;
    margin-left: 20px;
  }
  & a:hover {
    text-decoration: underline !important;
  }
`
const SendTo = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`
const FileInfo = styled.div`
  font-size: 12px;
  margin-left: 21px;
  .lang {
    text-transform: uppercase;
  }
`

const Docs = ({ lang, project }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [err, setErr] = useState(false)
  const [feedbackStatus, setFeedbackStatus] = useState(false)
  const [emptyPwd, setEmptyPassword] = useState(true)

  const apiUrl = 'https://www.andreamartines.it/download/doc/'
  const apiMailUrl = 'https://www.andreamartines.it/download/mail/'
  var pwd
  if (isBrowser) {
    pwd = localStorage.getItem('pwd')
  }

  const downloadFile = (documento, pw) => {
    pwd = localStorage.getItem('pwd')
    var formdata = new FormData()
    formdata.append('Id', documento.documentId)
    formdata.append('User', localStorage.getItem('id'))
    formdata.append('Pwd', pw || pwd)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    }
    var isBlob = false
    fetch(`${apiUrl}file.php`, requestOptions)
      .then((response) =>
        response.headers.get('Content-Type') ===
        'application/pdf; charset=UTF-8'
          ? ((isBlob = true), response.blob())
          : response.json()
      )
      .then((result) => {
        if (isBlob) {
          const blob = new Blob([result], {
            type: 'application/pdf',
          })
          const blobUrl = URL.createObjectURL(blob)
          var nome = `${project.title} - ${documento.label}`
          var link = document.createElement('a') // Or maybe get it from the current document
          link.style = 'display: none'
          link.href = blobUrl
          link.download = `${nome}.pdf`
          link.innerHTML = nome
          // const el = document.querySelector(`#file-${documento.idFile} span`)
          // el.innerHTML = ''
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(blobUrl)
          if (pw) {
            feedback('checked')
            localStorage.setItem('pwd', pw)
          }
        } else {
          setModalIsOpen(true)
          setErr(pw && result.error === '1' ? '3' : result.error)
        }
      })
      .catch((error) => console.log('error', error))
  }

  const checkPassword = (pw) => {
    var formdata = new FormData()
    formdata.append('Pwd', pw || pwd)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    }
    var isBlob = false
    fetch(`${apiUrl}check.php`, requestOptions)
      .then((response) =>
        response.headers.get('Content-Type') ===
        'application/pdf; charset=UTF-8'
          ? ((isBlob = true), response.blob())
          : response.json()
      )
      .then((result) => {
        if (result.error) {
          setErr(pw && result.error === '1' ? '3' : result.error)
        } else {
          feedback('checked')
          localStorage.setItem('pwd', pw)
          localStorage.setItem('id', result.id)
        }
      })
      .catch((error) => console.log('error', error))
  }

  const getFile = (e, doc) => {
    e.preventDefault()
    if (pwd) {
      window.gtag('event', 'page_view', {
        client_id: pwd,
        page_location: `${location.href}/download/${doc.label}/${doc.documentId}`,
      })
      downloadFile(doc)
    } else {
      setModalIsOpen(true)
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()
    sendMail()
  }
  const feedback = (type) => {
    setFeedbackStatus(type)
    document.getElementById('modal-front').style.display = 'none'
    document.getElementById('modal-feedback').style.display = 'block'
  }
  const resetModal = () => {
    document.getElementById('modal-front').style.display = 'block'
    document.getElementById('modal-feedback').style.display = 'none'
    setFeedbackStatus(false)
  }
  const sendMail = () => {
    let formData = new FormData()
    formData.append('name', document.getElementById('mailName').value)
    formData.append('from', document.getElementById('mailFrom').value)
    formData.append('msg', document.getElementById('mailMsg').value)

    const response = fetch(`${apiMailUrl}`, {
      method: 'POST',
      redirect: 'follow',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: formData,
    })
      .then((response) => response.text())
      .then((result) => feedback('sent'))
      .catch((error) => console.log('error', error))
  }
  const checkPwd = () => {
    const pw = document.getElementById('password').value
    if (pw === '') {
    } else {
      checkPassword(pw)
    }
  }
  const checkIfEmpty = () => {
    const pw = document.getElementById('password').value
    if (pw === '') {
      setEmptyPassword(true)
    } else {
      setEmptyPassword(false)
    }
  }
  const files = orderFilesForLang(lang, project.documents)
  const videos = [
    {
      slug: 'villafuera',
      label: 'Video presentazione',
      language: 'it',
      duration: '2.00m',
      url: 'https://www.andreamartines.it/progetti/villafuera/video/',
    },
  ]
  return (
    <>
      <Container>
        <Download>Download</Download>
        <div id="files">
          {files &&
            files.map((documento) => {
              return (
                <Doc>
                  <StaticImage
                    src="../img/doc1.png"
                    layout="fixed"
                    width={12}
                    height={16}
                    style={{ position: 'absolute', marginTop: '2px' }}
                  />
                  <span id={`file-${documento.documentId}`}>
                    <span>
                      <a
                        href="#"
                        onClick={(e) => {
                          getFile(e, documento)
                        }}
                      >
                        {documento.label}
                      </a>
                    </span>
                    <FileInfo>
                      <span className="lang">{documento.language}</span> -{' '}
                      {documento.numberOfPages && documento.numberOfPages}pp
                    </FileInfo>
                  </span>
                </Doc>
              )
            })}
          {videos &&
            videos.map((video, index) => {
              if (project.slug === video.slug)
                return (
                  <Doc>
                    <StaticImage
                      src="../img/video.png"
                      layout="fixed"
                      width={16}
                      height={16}
                      style={{ position: 'absolute', marginTop: '2px' }}
                    />
                    <span id={`video-${index}`}>
                      <span>
                        <a href={video.url} target="_blank">
                          {video.label}
                        </a>
                      </span>
                      <FileInfo>
                        <span className="lang">{video.language}</span> -{' '}
                        {video.duration && video.duration}
                      </FileInfo>
                    </span>
                  </Doc>
                )
            })}
        </div>
      </Container>
      {lang === 'it' && (
        <Modale
          {...{ modalIsOpen }}
          {...{ setModalIsOpen }}
          {...{ resetModal }}
        >
          <div id="modal-front">
            {err === '1' && <p>La password salvata non è più valida.</p>}
            {err === '2' && (
              <p>La password inserita non ha accesso a questo progetto.</p>
            )}
            {err === '3' && <p>La password inserita non è valida.</p>}

            <p>
              Inserisci {!err ? 'la password' : 'una password diversa'}:&nbsp;
              <input
                type="password"
                name="password"
                id="password"
                onChange={() => checkIfEmpty()}
              />
              &nbsp;
              <Pulsante disabled={emptyPwd} onClick={() => checkPwd()}>
                Vai
              </Pulsante>
              &nbsp;(serve solo la prima volta)
            </p>

            <p>
              Se non hai la password chiedimene una, specificando quale progetto ti interessa leggere, ci tengo al tuo parere!
            </p>
            <p>Potrai accedere a tutti i file del progetto quando vuoi.</p>
            <form id="formCreate" onSubmit={onSubmit}>
              <Chi>
                <Campo>
                  <Etichetta>Nome</Etichetta>
                  <input
                    style={{ width: '100%' }}
                    type="text"
                    name="mailName"
                    id="mailName"
                  />
                </Campo>
                <Campo>
                  <Etichetta>Email</Etichetta>
                  <input
                    style={{ width: '100%' }}
                    type="email"
                    name="mailFrom"
                    id="mailFrom"
                  />
                </Campo>
              </Chi>
              <Cosa>
                <Campo>
                  <Etichetta>Messaggio</Etichetta>
                  <textarea
                    style={{ width: '100%', fontFamily: 'Nunito' }}
                    name="mailMsg"
                    id="mailMsg"
                    rows="5"
                  />
                </Campo>
              </Cosa>

              <Pulsante style={{ float: 'right' }} type="submit">
                Manda
              </Pulsante>
            </form>
          </div>

          <div id="modal-feedback" style={{ display: 'none' }}>
            {feedbackStatus === 'sent' && (
              <>
                <p>
                  Grazie del messaggio,
                  <br />
                  <br />
                  ti mando una password appena possibile.
                  <br />
                  <br />
                  Ciao, a presto.
                </p>
                <p>
                  <b>Andrea</b>
                </p>
              </>
            )}
            {feedbackStatus === 'checked' && (
              <>
                <p>La password è corretta.</p>
                <p>
                  Ora puoi leggere tutto ciò che vuoi.
                  <br />
                  Se vuoi mandami opinioni e suggerimenti, saranno preziosi per
                  me.
                </p>
                <p>Ciao, a presto.</p>
                <p>
                  <b>Andrea</b>
                </p>
              </>
            )}
          </div>
        </Modale>
      )}
      {lang === 'en' && (
        <Modale
          {...{ modalIsOpen }}
          {...{ setModalIsOpen }}
          {...{ resetModal }}
        >
          <div id="modal-front">
            {err === '1' && <p>Your saved password is no longer valid.</p>}
            {err === '2' && (
              <p>The entered password does not have access to this project.</p>
            )}
            {err === '3' && <p>The entered password is no longer valid.</p>}
            <p>
              Insert {!err ? 'password' : 'a different password'}:&nbsp;
              <input
                type="password"
                name="password"
                id="password"
                onChange={() => checkIfEmpty()}
              />
              &nbsp;
              <Pulsante disabled={emptyPwd} onClick={() => checkPwd()}>
                Go
              </Pulsante>
              &nbsp;(only needed the first time)
            </p>

            <p>
            If you don't have a password, ask me for one, specifying which project you are interested in reading, your feedback is precious to me.
            </p>
            <p>You will be able to access all available files in the project, whenever you want.</p>
            <form id="formCreate" onSubmit={onSubmit}>
              <Chi>
                <Campo>
                  <Etichetta>Name</Etichetta>
                  <input
                    style={{ width: '100%' }}
                    type="text"
                    name="mailName"
                    id="mailName"
                  />
                </Campo>
                <Campo>
                  <Etichetta>Email</Etichetta>
                  <input
                    style={{ width: '100%' }}
                    type="email"
                    name="mailFrom"
                    id="mailFrom"
                  />
                </Campo>
              </Chi>
              <Cosa>
                <Campo>
                  <Etichetta>Message</Etichetta>
                  <textarea
                    style={{ width: '100%', fontFamily: 'Nunito' }}
                    name="mailMsg"
                    id="mailMsg"
                    rows="5"
                  />
                </Campo>
              </Cosa>

              <Pulsante style={{ float: 'right' }} type="submit">
                Send
              </Pulsante>
            </form>
          </div>

          <div id="modal-feedback" style={{ display: 'none' }}>
            {feedbackStatus === 'sent' && (
              <>
                <p>
                  Thanks for your feedback,
                  <br />
                  <br />
                  I'll send you a password as soon as possible.
                  <br />
                  <br />
                  Bye, see you soon!
                </p>
                <p>
                  <b>Andrea</b>
                </p>
              </>
            )}
            {feedbackStatus === 'checked' && (
              <>
                <p>The password is correct.</p>
                <p>
                  Now you can read all you want.
                  <br />
                  I'd love to hear your opinion and suggestions, send me your feedback, it will be precious.
                </p>
                <p>Bye, see you soon!</p>
                <p>
                  <b>Andrea</b>
                </p>
              </>
            )}
          </div>
        </Modale>
      )}
    </>
  )
}
export default Docs
