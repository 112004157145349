import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const Container = styled.div`
    margin: 10px 0;
`
const Image = styled(GatsbyImage)`
  width: 9rem;
  @media (min-width: 1400px) {
    max-width: 11rem;
  }
  @media (min-width: 1600px) {
    max-width: 12rem;
  }
  @media (max-width: 1200px) {
    max-width: 7rem;
  }
  @media (max-width: 1100px) {
    max-width: 6.5rem;
    flex-direction: column;
  }
  @media (max-width: 700px) {
    max-width: 54vw;
    }
  /* -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1); */

`

const AwardBadge = ({award, lang}) => {
  const im = award.image
  return (
    <Container>
      {im && (
        <Image
          alt={im.title}
          image={im.gatsbyImageData}
        />
      )}
    </Container>
  )
}
export default AwardBadge
