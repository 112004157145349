import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Page from '../page/page-layout'
import Carousel from '../../carousel'
import AwardBadge from './award'
import PrevNext from './prev-next'
import Docs from './docs'
import GoHome from '../../gohome.js'
import projectsOrder from '../../../../common/ordine-progetti'

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
`
const ProjectDetail = ({ project, lang, generi = '', tipi = '' }) => {
  const slides = []
  const selectItem = (item, field) => {
    var selected = item[field]
    return selected
  }
  const addImage = (item, otherLang) => {
    // const l = otherLang ? (lang === 'en' ? 'it' : 'en') : lang
    slides.push({
      img: selectItem(item, 'image')
        ? selectItem(item, 'image').gatsbyImageData
        : null,
      label: selectItem(item, otherLang ? 'strangerLabel' : 'label'),
    })
  }

  if (project.poster && project.poster.length) {
    // add poster and alternative versions
    project.poster.map((item) => {
      addImage(item)
    })
    // add other lang poster
    project.otherLangPosters.map((item, index) => {
      if (!index) addImage(item, true)
    })
  }
  if (project.images && project.images.length) {
    // add other project images
    project.images.map((item) => {
      if (item.type === 'Immagine') addImage(item)
    })
    // add inspiration movie posters
    project.images.map((item) => {
      if (item.type === 'Ispirazione') addImage(item)
    })
  }

  const Info = styled.dl`
    width: 16vw;
    margin: 60px auto 20px auto;
    padding-bottom: 30px;
    font-weight: 300;
    @media (max-width: 700px) {
      width: 80vw;
    }
  `
  const Definition = styled.dt`
    margin-top: 20px;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 1rem;
    text-transform: uppercase;
  `
  const Item = styled.dd`
    font-size: 1rem;
    font-family: 'Nunito';
    margin-inline-start: auto;
  `
  const side = (
    <>
      <Carousel
        // slideWidth={200}
        // slideGap={10}
        {...{ lang }}
        {...{ slides }}
      />
      <Info>
        <Definition>
          {lang === 'en' ? 'Development Status' : 'Fase di sviluppo'}
        </Definition>
        <Item>
          {project.developmentStatus.map((item) => {
            return (
              <>
                {item}
                <br />
              </>
            )
          })}
          {project.statusInProgress && ' (in progress)'}
        </Item>

        <Definition>{lang === 'en' ? 'Rights' : 'Diritti'}</Definition>
        <Item>{project.rights} </Item>

        <Definition>Budget</Definition>
        <Item>{project.budget} </Item>

        <Definition>{lang === 'en' ? 'Production' : 'Produzione'}</Definition>
        <Item>
          {project.production && (
            <>
              {project.production[0]}
              {project.production[1] ? ` / ${project.production[1]}` : ''}
            </>
          )}
        </Item>
        {project.length && (
          <>
            <Definition>{lang === 'en' ? 'Runtime' : 'Durata'}</Definition>
            <Item>{project.length}'</Item>
          </>
        )}
        {project.episodeLength && (
          <>
            <Definition>
              {lang === 'en' ? 'Episode Runtime' : 'Durata episodi'}
            </Definition>
            <Item>{project.episodeLength}'</Item>
          </>
        )}
        {project.numberOfEpisodes && (
          <>
            <Definition>
              {lang === 'en' ? 'First Season' : 'Prima stagione'}
            </Definition>
            <Item>
              {project.numberOfEpisodes}{' '}
              {lang === 'en' ? 'episodes' : 'episodi'}
            </Item>
          </>
        )}
        {project.ageRating && (
          <>
            <Definition>
              {lang === 'en' ? 'Age Rating' : 'Classificazione'}
            </Definition>
            <Item>{project.ageRating}</Item>
          </>
        )}
      </Info>
    </>
  )

  const Header = styled.header`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  `
  const Title = styled.h1`
    font-family: 'Montserrat';
    font-weight: 700;
    color: black;
    flex: 3;
    margin: 0;
    z-index: 100;
    position: relative;
    font-size: 2.5rem;
    @media (min-width: 1400px) {
      font-size: 2.8rem;
    }
    @media (min-width: 1600px) {
      font-size: 3.4rem;
    }
    @media (max-width: 1200px) {
      font-size: 2.3rem;
    }
    @media (max-width: 1100px) {
      font-size: 2.2rem;
      flex-direction: column;
    }
  `
  const SubHeader = styled.footer`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    font-weight: 400;
    font-family: 'Lato';
    margin-top: 20px;
  `
  const Details = styled.div`
    align-items: flex-start;
    flex: 6;
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 1rem;
    @media (min-width: 1400px) {
      font-size: 1.2rem;
    }
    @media (min-width: 1600px) {
      font-size: 1.4rem;
    }
    @media (max-width: 1200px) {
      font-size: 0.9rem;
    }
    @media (max-width: 1100px) {
      font-size: 0.85rem;
      flex-direction: column;
    }
  `
  const Anno = styled.span`
    padding: 3px;
    margin: 10px 10px 10px 0;
    font-weight: 700;
  `
  const Tipo = styled.span`
    text-transform: uppercase;
    padding: 3px 3px 3px 0;
    margin: 10px 10px 10px 0;
    @media (max-width: 700px) {
      width: 100%;
      display: inline-block;
      margin: 6px 0 0 0;
      padding: 0;
    }
  `
  const Genere = styled.span`
    flex: 1;
    padding: 3px;
    margin: 10px;
    font-style: italic;
    @media (max-width: 700px) {
      width: 100%;
      display: inline-block;
      margin: 6px 0 0 0;
      padding: 0;
    }
  `
  const Content = styled.div`
    flex: 1;
    flex-grow: unset;
    line-height: 170%;
    font-weight: 300;
    font-family: 'Montserrat';
    font-size: 1.2rem;
    @media (min-width: 1400px) {
      font-size: 1.4rem;
    }
    @media (min-width: 1600px) {
      font-size: 1.7rem;
    }
    @media (max-width: 1200px) {
      font-size: 1.1rem;
    }
    @media (max-width: 1100px) {
      font-size: 1rem;
      flex-direction: column;
    }
  `
  const main = (
    <>
      <GoHome {...{ lang }} />
      <Header>
        <Title>{project.title}</Title>
      </Header>
      <SubHeader>
        <Details>
          {/*          <Anno className="visible">{project.year}</Anno> */}
          <Tipo className="visible">
            {(tipi = '')}
            {project.type &&
              project.type.forEach((item) => {
                tipi += ' / ' + item
              })}
            {tipi.substring(2)}
          </Tipo>
          <Genere className="visible">
            {(generi = '')}
            {project.genre &&
              project.genre.forEach((item) => {
                generi += ', ' + item
              })}
            {generi.substring(2)}
          </Genere>
        </Details>
      </SubHeader>
      <Content>
        {project.synopsis && project.synopsis.childMarkdownRemark && (
          <div
            dangerouslySetInnerHTML={{
              __html: project.synopsis.childMarkdownRemark.html,
            }}
          />
        )}
      </Content>
    </>
  )

  const Awards = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 30px;
    justify-content: space-around;
  `

  const secondary = (
    <>
      <PrevNext lang={lang} slug={project.slug} position={'up'} />
      <Awards>
        {project.awards &&
          project.awards.map((item) => {
            return <AwardBadge award={item} {...{ lang }} />
          })}
      </Awards>
      <Docs {...{ lang }} {...{ project }} />
      <PrevNext lang={lang} slug={project.slug} position={'down'} />
    </>
  )
  return <Page {...{ side }} {...{ main }} {...{ secondary }} />
}
export default ProjectDetail
