import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import projectsOrder from '../../../../common/ordine-progetti'

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
`
const Container = styled.div`
  margin: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  width: auto;
  min-height: 3vh;
  top: 1vh;
  right: 8vw;
  line-height: 1em;
  /* transform-origin: 100% 100%; */
  @media (max-width: 700px) {
    left: 10vw;
  } ;
`
const ContainerDown = styled(Container)`
  position: relative;
  margin-bottom: 30px;
  line-height: 1.5rem;
  left: 0 !important;
  @media (min-width: 900px) {
    display: none;
  } ;
`
const Txt = styled.h2`
  font-weight: 300;
  font-family: 'Lato';
  -webkit-letter-spacing: 0.3rem;
  -moz-letter-spacing: 0.3rem;
  -ms-letter-spacing: 0.3rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  color: #000;
  font-size: 1.1rem !important;
  @media (min-width: 1400px) {
    font-size: 1.3rem;
  }
  @media (min-width: 1600px) {
    font-size: 1.6rem;
  }
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
  @media (max-width: 1100px) {
    font-size: 0.9rem;
    flex-direction: column;
  }
  &:hover {
    color: #c6091c;
  }
`
const TxtDown = styled(Txt)`
  -webkit-letter-spacing: 0rem;
  -moz-letter-spacing: 0rem;
  -ms-letter-spacing: 0rem;
  letter-spacing: 0rem;
`

const Next = styled.span`
  @media (max-width: 1100px) {
    display: none;
  }
`
const NextDown = styled.div``

const NextSubstitute = styled.span`
  display: inline-block;
  margin-right: 10px;
  @media (min-width: 1100px) {
    display: none;
  }
  @media (max-width: 900px) {
    display: none;
  }
`
const Project = styled.span`
  @media (max-width: 900px) {
    display: none;
  }
`
const ProjectDown = styled.div``
const pageExists = (page) => {
  const exists = projectsOrder.havePage.indexOf(page) > -1
  return exists
}
const nextLink = (lang, slug, position) => {
  var nextProjectIndex = projectsOrder[lang].indexOf(slug) + 1
  if (!pageExists(nextProjectIndex))
    nextProjectIndex = projectsOrder.havePage[0]
  return (
    <StyledLink
      to={
        lang === 'en'
          ? `/en/projects/${projectsOrder[lang][nextProjectIndex]}`
          : `/progetti/${projectsOrder[lang][nextProjectIndex]}`
      }
    >
      {position === 'up' && (
        <Txt>
          <Next>{lang === 'en' ? 'Next' : 'Prossimo'}: </Next>
          <NextSubstitute>&gt;</NextSubstitute>
          <Project>{projectsOrder[`${lang}Title`][nextProjectIndex]}</Project>
        </Txt>
      )}
      {position === 'down' && (
        <TxtDown>
          <NextDown>{lang === 'en' ? 'Next' : 'Prossimo'}: </NextDown>
          <ProjectDown>{projectsOrder[`${lang}Title`][nextProjectIndex]}</ProjectDown>
        </TxtDown>
      )}
    </StyledLink>
  )
}

const PrevNext = ({ slug, lang, position }) => {
  return (
    <>
      {position === 'up' && (
        <Container>{nextLink(lang, slug, position)}</Container>
      )}
      {position === 'down' && (
        <ContainerDown>{nextLink(lang, slug, position)}</ContainerDown>
      )}
    </>
  )
}
export default PrevNext
