import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'

import { GatsbyImage } from 'gatsby-plugin-image'

const Container = styled.div`
  /* margin: 10px 0; */
`
const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
  /* z-index: 9999999; */
  position: fixed;
  top: 0;
  left: 0;
  /* margin: 10px 0; */
`
const Close = styled.div`
//  float: right;
  position: absolute;
  right: 20px;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: bold;
  /* margin: 10px 0; */
`

const Modale = ({ children, modalIsOpen, setModalIsOpen, resetModal }) => {
  const customStyles = {
    overlay: {
      position: 'relative',
      // backgroundColor: 'grey',
      // opacity: 0.5,
      // zIndex: 9999,
    },
    content: {
      position: 'fixed',
      display: 'flex',
      flexGrow: '4',
      justifyContent: 'center',
//      alignItems: 'center',
      zIndex: '9999',
//      width: '50vw',
      margin: '10vw',
      maxWidth: '800px',
//      minWidth: '400px',

      // position: 'fixed',
      // top: '70%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginTop: '10%',
      // marginRight: '-50%',
      // transform: 'translate(-50%, -150%)',
      // opacity: 1,
      // zIndex: 9999,
      // width: '50vw',
      fontFamily: 'Nunito',
      // background: 'yellow',
    },
  }
  Modal.setAppElement('#___gatsby')

  // let subtitle
  // const [modalIsOpen, setModalIsOpen] = React.useState(false);

  function openModal() {
    setModalIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function closeModal() {
    setModalIsOpen(false)
    resetModal()
  }
  // console.log(modalIsOpen)
  return (
    <Container>
      {modalIsOpen && <Overlay />}
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <Close onClick={closeModal}>X</Close>
        {/* <div>I am a modal</div> */}
        {children}
      </Modal>
    </Container>
  )
}
export default Modale
